<template>
  <v-main>
    <v-container>
      <v-row
        no-guttery
      >
        <v-col
          cols="12"
        >
          <v-card
            color="transparent"
            flat
            class="d-flex"
          >

            <v-card-text style="font-size: 20px; line-height: 1.5;">
              <div class="d-flex align-baseline">
                <h1 class="display-2 font-weight-bold"  v-if="!isLoading">{{link.name}}</h1>
              </div>
            <v-card-text
              style="margin-bottom: 100px; width: auto;"
            >
            </v-card-text>
              <!-- <div v-if="!isLoading">Name: {{link.name}}</div> -->
            <v-row>
              <v-card class="col-md-5">
                <v-skeleton-loader
                  v-if="isLoading"
                  transition="fade"
                  type="article"
                ></v-skeleton-loader>
                <div v-if="!isLoading">
                  <v-icon>mdi-alien</v-icon>
                  Name: {{link.name}}</div>
                <div v-if="!isLoading">
                  <v-icon>mdi-link-variant</v-icon>
                  Link: {{link.link}}</div>
                <div v-if="!isLoading">
                  <v-icon>mdi-link-variant-off</v-icon>
                  Short link: {{link.short_link}}</div>
                <div v-if="!isLoading">
                  <v-icon>mdi-security</v-icon>
                  Password: {{link.password}}</div>
                <div v-if="!isLoading">
                  <v-icon>mdi-comment-outline</v-icon>
                  Comment: {{link.comment}}</div>
                <div v-if="!isLoading"> Created by: {{link.created_by}}</div>
                <div v-if="!isLoading"> Created at: {{ formattedCreateddAt }}</div>
                <div v-if="!isLoading && link.edited_by">Edited by: {{link.edited_by}}</div>
                <div v-if="!isLoading && link.edited_by">Edited at: {{ formattedUpdatedAt }}</div>
              </v-card>
              <v-col class="pa-2"></v-col>
              <v-card class="col-md-5">
                <v-skeleton-loader
                  v-if="isLoading"
                  transition="fade"
                  type="article"
                ></v-skeleton-loader>
                <div v-if="!isLoading">
                  <apexchart type="pie" height="250" :options="browserChartOptions" :series="browserChartSeries"></apexchart>
                </div>
              </v-card>
            </v-row>
            <v-row style="padding-top: 50px;">
              <v-card class="col-md-5">
                <v-skeleton-loader
                  v-if="isLoading"
                  transition="fade"
                  type="article"
                ></v-skeleton-loader>
                <div v-if="!isLoading">
                  <apexchart type="pie" height="250" :options="referrerChartOptions" :series="referrerChartSeries"></apexchart>
                </div>
              </v-card>
              <v-col class="pa-2"></v-col>
              <v-card class="col-md-5">
                <v-skeleton-loader
                  v-if="isLoading"
                  transition="fade"
                  type="article"
                ></v-skeleton-loader>
                <div v-if="!isLoading">
                  <apexchart type="pie" height="250" :options="platformChartOptions" :series="platformChartSeries"></apexchart>
                  <!-- <apexchart type="pie" width="380" :options="referrerChartOptions" :series="platformChartSeries"></apexchart> -->
                </div>
              </v-card>
            </v-row>
            <v-row style="padding-top: 50px;">
              <v-card class="col-md-12">
                <v-skeleton-loader
                  v-if="isLoading"
                  transition="fade"
                  type="article"
                ></v-skeleton-loader>
                <div v-if="!isLoading">
                  <div id="chart">
                    <apexchart type="area" height="350" :options="timelineChartOptions" :series="timelineChartSeries"></apexchart>
                  </div>
                </div>
              </v-card>
            </v-row>
            <v-row style="padding-top: 50px;">
              <v-card class="col-md-12">
                <v-skeleton-loader
                  v-if="isLoading"
                  transition="fade"
                  type="article"
                ></v-skeleton-loader>
                <div v-if="!isLoading">
                  <MapChart
                    :countryData="mapChartSeries"
                    highColor="#008ffb"
                    lowColor="#aaaaaa"
                    countryStrokeColor="#909090"
                    defaultCountryFillColor="#dadada"
                    legendFontColorHeader="black"
                    legendHeaderBackgroundColor="#B0BEC5"
                    legendContentBackgroundColor="#CFD8DC"
                    :currencyAdd="false"
                    LangUser="en"
                  />
                </div>
              </v-card>
            </v-row>
            <v-row style="padding-top: 50px;">
              <v-card class="col-md-12">
                <template>
                  <v-data-table
                    :headers="headers"
                    :items="groupByIps"
                    :search="search"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <!-- </v-toolbar> -->
                      <v-text-field
                        v-model="search"
                        label="Search"
                        class="mx-4"
                      ></v-text-field>
                    </template>
                    <template v-slot:item.createdAt="{ item }">
                      <span>{{ formatDate(item.createdAt) }}</span>
                    </template>
                  </v-data-table>
                </template>
              </v-card>
            </v-row>
            <!-- <v-card>
              <v-skeleton-loader
              v-if="isLoading"
              transition="fade"
              type="article"
            ></v-skeleton-loader>
              <div
                v-if="!isLoading"
                class="mt-10"
                style="max-width: 50%; font-size: 20px; line-height: 1.5;"
              >
                <div>Clicks by countries:</div>
                <ul v-for="(country, index) in groupByCountries" :key="index">
                  <li>{{country.country}}: {{country.count}}</li>
                    <ul v-for="(ip, ipIndex) in country.ipList" :key="ipIndex">
                      <li> {{ip.ip}} -- {{ ip.count }} </li>
                    </ul>
                </ul>
              </div>
            </v-card> -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-footer>
        LoveStyle Redirect
      </v-footer> -->
    </v-container>
  </v-main>
</template>
<script>
import moment from 'moment'
import VueApexCharts from 'vue-apexcharts'
import MapChart from 'vue-chart-map'

export default {
  name: 'Link',
  components: {
    apexchart: VueApexCharts,
    MapChart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      // required: true,
    },
  },
  data() {
    return {
      link: '',
      search: '',
      searchGrouped: '',
      isLoading: true,
      headers: [
        {
          text: 'Country',
          align: 'start',
          value: 'country',
        },
        {
          text: 'Country code',
          value: 'country_code',
          filterable: false,
          align: 'center',
        },
        { text: 'IP', value: 'ip', align: 'center' },
        { text: 'Clicks', value: 'totalClicks', align: 'center' },
        { text: 'Time', value: 'createdAt', align: 'right' },
      ],
      mapChartSeries: [],
      browserChartSeries: [],
      platformChartSeries: [],
      referrerChartSeries: [],
      timelineChartSeries: [{
        name: 'views',
        data: [],
      }],
      browserChartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        // color: 'white',
        legend: {
          show: true,
          labels: {
            colors: undefined,
            useSeriesColors: true,
          },
        },
        title: {
          text: 'Browsers',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            // fontFamily: undefined,
            color: 'white',
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
            donut: {
              labels: {
                // show: true,
                // name: {
                //   show: true,
                //   color: '#d43737',
                // },
                // value: {
                //   show: true,
                //   color: 'yellow',
                // },
                // total: {
                //   show: true,
                //   color: 'green',
                // },
              },
            },
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'left',
            },
          },
        }],
      },
      platformChartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        // color: 'white',
        legend: {
          show: true,
          labels: {
            colors: undefined,
            useSeriesColors: true,
          },
        },
        title: {
          text: 'Platforms',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            // fontFamily: undefined,
            color: 'white',
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'left',
            },
          },
        }],
      },
      referrerChartOptions: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: [],
        // color: 'white',
        legend: {
          show: true,
          labels: {
            colors: undefined,
            useSeriesColors: true,
          },
        },
        title: {
          text: 'Referrers',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            // fontFamily: undefined,
            color: 'white',
          },
        },
        plotOptions: {
          pie: {
            dataLabels: {
              enabled: false,
            },
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'left',
            },
          },
        }],
      },
      timelineChartOptions: {
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            type: 'x',
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: 'zoom',
          },
        },
        dataLabels: {
          enabled: true,
          background: {
            enabled: true,
            foreColor: '#fff',
          },
        },
        markers: {
          size: 0,
        },
        title: {
          text: 'Views',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: 'bold',
            // fontFamily: undefined,
            color: 'white',
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: ['white'],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
            // formatter: val => (val / 1000000).toFixed(0),
          },
          title: {
            text: 'Clicks',
            style: {
              color: 'white',
            },
          },
        },
        grid: {
          // borderColor: 'red',
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: {
              colors: 'white',
            },
          },
          title: {
            style: {
              color: 'white',
            },
          },
        },
        tooltip: {
          theme: 'dark',
          // fillSeriesColor: true,
          shared: false,
          style: {
            fontSize: '20px',
          },
          // x: {
          //   show: true,
          //   // format: 'dd MMM',
          //   formatter: undefined,
          // },
          y: [
            {
              // title: {
              //   formatter: val => `${val} clicks`,
              // },
            },
          ],
          // y: {
          //   formatter: val => (val / 1000000).toFixed(0),
          // },
        },
      },
    }
  },
  async mounted() {
    await this.getStatistics(this.id)
  },
  methods: {
    formatDate: value => moment(value).format('YYYY-MM-DD HH:mm:ss'),
    async getStatistics(id) {
      this.isLoading = true

      const response = await fetch(`/api/stat/${id}`, {
        method: 'GET',
        credentials: 'include',
      })

      const data = await response.json()
      console.log(data)
      document.title = data.data.link.name
      this.link = data.data.link
      // this.groupBy = []
      this.isLoading = false
      if (this.link) {
        // browsers
        const groupBy = Object.values(this.link.clicks.reduce((acc, curr) => {
          const tempBrowser = curr.browser || 'Other'
          if (acc[tempBrowser]) {
            acc[tempBrowser].count++
          } else {
            acc[tempBrowser] = { browser: tempBrowser, count: 1 }
          }
          return acc
        }, {})).map(el => el)
        this.browserChartSeries = groupBy.map(el => el.count)
        this.browserChartOptions.labels = groupBy.map(el => el.browser)

        // platforms
        const platformsGroupBy = Object.values(this.link.clicks.reduce((acc, curr) => {
          const tempPlatform = curr.platform || 'Other'
          if (acc[tempPlatform]) {
            acc[tempPlatform].count++
          } else {
            acc[tempPlatform] = { platform: tempPlatform, count: 1 }
          }
          return acc
        }, {})).map(el => el)
        this.platformChartSeries = platformsGroupBy.map(el => el.count)
        this.platformChartOptions.labels = platformsGroupBy.map(el => el.platform)

        // referrers
        const referrerGroupBy = Object.values(this.link.clicks.reduce((acc, curr) => {
          const tempReferrer = curr.referrer || 'Other'
          if (acc[tempReferrer]) {
            acc[tempReferrer].count++
          } else {
            acc[tempReferrer] = { referrer: tempReferrer, count: 1 }
          }
          return acc
        }, {})).map(el => el)
        this.referrerChartSeries = referrerGroupBy.map(el => el.count)
        this.referrerChartOptions.labels = referrerGroupBy.map(el => el.referrer)

        // timeline
        const timelineGroupBy = Object.values(this.link.clicks.reduce((acc, curr) => {
          const tempDay = moment(curr.createdAt).format('YYYY-MM-DD')
          if (acc[tempDay]) {
            acc[tempDay].count++
          } else {
            acc[tempDay] = { day: tempDay, count: 1, date: curr.createdAt }
          }
          return acc
        }, {})).map(el => el)
        this.timelineChartSeries[0].data = timelineGroupBy.map(el => ({ x: new Date(el.date).getTime(), y: el.count })).sort((a, b) => a.x - b.x)
        // this.timelineChartSeries[0].data = this.link.clicks.map(el => ({ x: new Date(el.createdAt).getTime(), y: 1 }))

        // map
        const mapGroupBy = Object.values(this.link.clicks.reduce((acc, curr) => {
          if (acc[curr.country_code]) {
            acc[curr.country_code].count++
            console.log(curr, acc)
            if (!acc[curr.country_code].ipList.some(el => el.ip === curr.ip)) {
              acc[curr.country_code].ipList.push({ ip: curr.ip, count: 1 })
            } else {
              acc[curr.country_code].ipList.find(el => el.ip === curr.ip).count++
            }
          } else {
            acc[curr.country_code] = {
              count: 1,
              country_code: curr.country_code,
              country: curr.country,
              ipList: [{ ip: curr.ip, count: 1 }],
            }
          }
          return acc
        }, {})).map(el => el)
        const tempMapChartSeries = {}
        for (let country = 0; country < mapGroupBy.length; country++) {
          if (mapGroupBy[country].country_code) tempMapChartSeries[mapGroupBy[country].country_code] = mapGroupBy[country].count
        }
        this.mapChartSeries = tempMapChartSeries
      }
      console.log('link data', this.mapChartSeries)
    },
  },
  computed: {
    // FIXME: komment, miért computedben kell ezt elvégezni
    formattedUpdatedAt() {
      return moment(this.link.updatedAt).format('YYYY-MM-DD HH:mm:ss')
    },
    formattedCreateddAt() {
      return moment(this.link.createdAt).format('YYYY-MM-DD HH:mm:ss')
    },
    groupByCountries() {
      console.log('computed#groupByCountries')
      if (!this.link) return []
      const groupBy = Object.values(this.link.clicks.reduce((acc, curr) => {
        if (acc[curr.country_code]) {
          acc[curr.country_code].count++
          console.log(curr, acc)
          if (!acc[curr.country_code].ipList.some(el => el.ip === curr.ip)) {
            acc[curr.country_code].ipList.push({ ip: curr.ip, count: 1 })
          } else {
            acc[curr.country_code].ipList.find(el => el.ip === curr.ip).count++
          }
        } else {
          acc[curr.country_code] = {
            count: 1,
            country_code: curr.country_code,
            country: curr.country,
            ipList: [{ ip: curr.ip, count: 1 }],
          }
        }
        return acc
      }, {})).map(el => el)
      return groupBy
    },
    groupByIps() {
      console.log('computed#groupByIps')
      if (!this.link) return []
      const groupBy = Object.values(this.link.clicks.reduce((acc, curr) => {
        if (acc[curr.ip]) {
          acc[curr.ip].count++
        } else {
          acc[curr.ip] = {
            count: 1,
            ip: curr.ip,
            country_code: curr.country_code,
            country: curr.country,
          }
        }
        return acc
      }, {})).map(el => el)
      const detailedClicks = this.link.clicks.map(el => {
        const details = el
        details.totalClicks = groupBy.find(elem => el.ip === elem.ip).count
        console.log(details.totalClicks)
        return el
      })
      return detailedClicks
    },
  },

  watch: {
    // FIXME: komment, mit csinálnak a watchban lévő functionök? Miért kellenek?
    link(val, oldVal) {
      console.log('link val', val)
      console.log('link oldVal', oldVal)
    },
    async id(val) {
      if (!val) return

      await this.getlinkData(val)
    },
  },
}
</script>

<style lang="scss" scoped>
  .m {
    &-bottom-gradient {
      background: linear-gradient(180deg, rgba(18, 18, 18, 0.2) 30%, #121212), linear-gradient(0deg, #121212 20%, rgba(25, 26, 36, 0.3));
    }
  }
</style>
