export default {
  namespaced: true,
  // FIXME: komment, state, hol és mire használjuk, miér van rá szükség
  state: {
    status: '',
    user: '',
    csrftoken: '',
  },
  // FIXME: komment, mire kellenek, hol használjuk
  getters: {
    isLoggingIn: state => state.status === 'loading',
    isLoggedIn: state => state.status === 'success',
    user: state => state.user,
    csrftoken: state => state.csrftoken,
  },
  // FIXME: komment, mire kellenek, hol használjuk
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state) {
      state.status = 'success'
    },
    auth_error(state) {
      state.status = 'error'
    },
    auth_user(state, data) {
      state.user = data.user
      state.csrftoken = data.csrftoken
    },
    logout(state) {
      state.status = ''
    },
  },
  actions: {
    // FIXME: komment, commit mit tartalmaz és hogy hívódik meg, miért van a store-ban?
    async login({ commit }, { user, password, csrftoken }) {
      commit('auth_request')
      console.log('auth#checkSession -> login form user: ', user)
      console.log('auth#checkSession -> login form password: ', password)

      let data = null
      try {
        const response = await fetch('/api/login', {
          method: 'POST',
          body: JSON.stringify({ email: user, password }),
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrftoken,
          },
          credentials: 'include',
        })
        data = await response.json()

        if (!response.ok) {
          throw new Error(data.errorMsg)
        }

        console.log('auth#checkSession -> response json', data)

        commit('auth_success')
      } catch (err) {
        commit('auth_error')
        throw (err)
      }

      return data
    },
    // FIXME: komment, commit mit tartalmaz és hogy hívódik meg, miért van a store-ban?
    async checkSession({ commit }) {
      try {
        const response = await fetch('/api/me', {
          method: 'GET',
          credentials: 'include',
        })

        const data = await response.json()

        if (data.code && data.code === 401) {
          commit('auth_error')
          throw new Error(data.message)
        }
        console.log('auth#checkSession -> login session data raw:', response)
        console.log('auth#checkSession -> login session data json:', data)
        commit('auth_success')
        commit('auth_user', { user: data.user, csrftoken: data.csrftoken })
      } catch (err) {
        commit('auth_error')
        throw (err)
      }
    },
    async logout({ commit }) {
      try {
        const response = await fetch('/api/logout', {
          method: 'GET',
          credentials: 'include',
        })

        console.log('logout session response: ', response)
        console.log('logout session response json: ', await response.json())

        if (!response.ok) {
          throw new Error('logout error')
        }

        commit('logout')
      } catch (err) {
        commit('auth_error')
        throw (err)
      }
    },
  },
}
