<template>
  <v-main>
    <v-container
      fluid
      fill-height
    >
      <v-row class="mx-0 px-0 justify-center">
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="3"
        >
          <v-card class="elevation-12">
            <v-toolbar>
              <v-toolbar-title>Login</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-form @submit.prevent="login" ref="loginForm">
              <v-card-text>
                <v-text-field
                  type="text"
                  name="login"
                  label="Email"
                  required
                  outlined
                  v-model="input.user"
                  :rules="[rules.required, rules.max]"
                  prepend-icon="mdi-account"
                ></v-text-field>
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  name="password"
                  label="Password"
                  required
                  outlined
                  v-model="input.password"
                  :rules="[rules.required]"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  prepend-icon="mdi-lock"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  color="cyan"
                  block
                  :loading="isLoggingIn"
                >Sign in</v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data() {
    return {
      showPassword: false,
      input: {
        user: '',
        password: '',
        csrftoken: '',
      },
      rules: {
        required: v => !!v || 'This field is required.',
        max: v => (v && v.length) <= 320 || 'Max. 320 characters.',
      },
    }
  },
  async created() {
    const response = await fetch('/api/csrf', {
      method: 'GET',
      credentials: 'include',
    })
    const responseData = await response.json()
    this.input.csrftoken = responseData.csrftoken
    console.log('created -> response json', responseData)
  },
  methods: {
    async login() {
      if (!this.$refs.loginForm.validate()) return

      const { user, password, csrftoken } = this.input

      try {
        // FIXME: komment, ez a vuex store-ból jön? hogy jut el az auth/login -ra?
        await this.$store.dispatch('auth/login', { user, password, csrftoken })
        // FIXME: komment, ez miért kell? ha nem az alap oldalon lépne be akkor navigálja oda?
        this.$router.push('/')
      } catch (err) {
        console.error(err)
        this.$root.$toast(err, { color: 'error' })
      }
    },
  },
  computed: {
    // FIXME: komment, erre miért van szükség?
    ...mapGetters('auth', ['isLoggingIn']),
  },
}
</script>
