<template>
  <v-menu
    bottom
    left
    offset-y
    v-if="isLoggedIn"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        dark
        v-on="on"
      >
        {{ user.nickname }}
        <v-icon class="ml-2">mdi-account</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click.prevent="logout">
        <v-list-item-title>Logout</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppMenu',
  methods: {
    async logout() {
      try {
        await this.$store.dispatch('auth/logout')
        this.$router.push('/login')
      } catch (err) {
        console.error(err)
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'user']),
  },
}
</script>
