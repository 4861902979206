import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Dashboard from '@/views/Dashboard/dashboard.vue'
import Stat from '@/views/Statistic/index.vue'
import Login from '@/views/Login/index.vue'
import PageNotFound from '@/views/PageNotFound/index.vue'

// FIXME: komment, ha itt mondom meg, hogy használja a VueRoutert de csak alul hozok létre új routert és azt exportálom akkor ez miért kell?
// Nem lehetne a main-ben? csak kérdés. Ha nem, miért nem? ha úgyis ott hozok létre new Vue()-t
Vue.use(VueRouter)
const DEFAULT_TITLE = 'TITLE MISSING'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      requiresAuth: true, // FIXME: true
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/stat/:id',
    name: 'stat',
    component: Stat,
    props: true,
    meta: {
      title: route => route.params.title,
      requiresAuth: true, // FIXME: true
    },
  },
  {
    path: '*',
    component: PageNotFound,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
// FIXME: komment, miért itt ellenőrizzük a sessiont? hogy hívódik meg, min keresztül?
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      await store.dispatch('auth/checkSession')
      next()
    } catch (err) {
      console.error(err)
      next('/login')
    }
  } else {
    // TODO: akkor is nézze meg, hogy van-e session ha nem kötelező, így fel tudok iratkozni más namespacere a socketioval FIXME: átgondolni
    try {
      await store.dispatch('auth/checkSession')
    } catch (err) {
      console.error(err.message)
    }
    next()
  }
})

router.afterEach(to => {
  document.title = typeof to.meta.title === 'function' ? to.meta.title(to) : to.meta.title || DEFAULT_TITLE
})

export default router
