import moment from 'moment'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// Vue.prototype.moment = moment

Vue.config.productionTip = false

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: SocketIO('https://localhost:8795', { autoConnect: true }), // options object is Optional
//   vuex: {
//     store,
//     actionPrefix: 'SOCKET_',
//     mutationPrefix: 'SOCKET_',
//   },
// }))
// const ioInstance = SocketIO('https://localhost:8795/user', {
//   reconnection: true,
//   reconnectionDelay: 500,
//   maxReconnectionAttempts: Infinity,
//   autoConnect: false,
// })
// // const socket = SocketIO('https://localhost:8795', { autoConnect: true })
// Vue.use(VueSocketIO, ioInstance, {
//   store, // vuex store instance
//   actionPrefix: 'SOCKET_', // keep prefix in uppercase
//   // eventToActionTransformer: (actionName) => actionName // cancel camel case
// })

// FIXME: komment, mi a regisztrálási folyamat? Ha pl.: a routert és a store-t külön paraméterezem fel és ott adom meg, hogy Vue.use(valami)
new Vue({
  router,
  store,
  vuetify,
  moment,
  render: h => h(App),
}).$mount('#app')
