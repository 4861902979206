<template>
  <v-snackbar
    v-model="isSnackbarVisible"
    :left="snackbar.location === 'left'"
    :right="snackbar.location === 'right'"
    :top="snackbar.location === 'top'"
    :bottom="snackbar.location === 'bottom'"
    :color="snackbar.color"
    :multi-line="snackbar.mode === 'multi-line'"
    :timeout="snackbar.timeout"
    :vertical="snackbar.mode === 'vertical'"
  >
    {{ snackbar.message }}
    <v-btn
      dark
      text
      :color="snackbar.close.color"
      @click="hide"
    >
      {{ snackbar.close.text }}
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      isSnackbarVisible: false,
      snackbar: {
        color: 'success',
        // mode: 'multi-line',
        location: 'top',
        message: 'Üres üzenet!',
        timeout: 3000,
        close: {
          text: 'Close',
          color: '',
        },
      },
    }
  },
  methods: {
    /**
     * Snackbar megjelenítése
     * @param {string} message
     * @param {object} snackbarOptions
    */
    show(message, snackbarOptions) {
      if (message) this.snackbar.message = message

      if (typeof snackbarOptions === 'object') {
        this.snackbar = Object.assign(this.snackbar, snackbarOptions)
      }

      this.isSnackbarVisible = true
    },
    hide() {
      this.isSnackbarVisible = false
    },
  },
}
</script>
