<template>
  <v-app dark>
    <v-app-bar
      app
    >
      <!-- <router-link to="/">API-k</router-link>
      <router-link to="test" class="ml-5">Tesztmegjelenítés</router-link> -->

      <router-link
        :to="{ name: 'dashboard' }"
        class="m-link"
      >
        <!-- <v-icon>mdi-heart</v-icon> -->
        <v-card-title class="white--text">
            <v-avatar size="56" class="">
              <img
                alt="LoveStyle Redirect"
                src="@/assets/LSR_logo.png"
              >
            </v-avatar>
            <span class="ml-3 hidden-sm-and-down">
              LoveStyle Redirect
            </span>
        </v-card-title>
        <!-- <v-img
         src="@/assets/LSR_logo.png"
         max-height="50"
         contain
         inline-flex
        ></v-img>
        <span> LoveStyle Redirect</span> -->
        <!-- LoveStyle Redirect -->
      </router-link>

      <v-spacer/>

      <!-- <v-autocomplete
        v-model="input.show"
        :items="items"
        :loading="autocomplete.isLoading"
        :search-input.sync="autocomplete.search"
        flat
        clearable
        color="white"
        class="mr-4 ml-10"
        hide-details
        item-text="titleShort"
        item-value="id"
        outlined
        placeholder="Start typing to search"
        return-object
        solo
      >
        <template v-slot:item="{ attr, on, item }">
          <v-list-item
            v-bind="attr"
            v-on="on"
            @click="navigateToShow(item)"
          >
            <v-list-item-content>{{item.titleShort}}</v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete> -->

      <app-menu />
    </v-app-bar>
    <router-view />
    <v-footer padless>
      <v-col
        class="text-center"
        cols="12"
      >
        <!-- <router-link
          :to="{ name: 'dashboard' }"
          class="m-link"
        >
          <v-icon>mdi-heart</v-icon>
          LoveStyle Redirect
        </router-link> -->
        <router-link
          :to="{ name: 'dashboard' }"
          class="m-link"
        >
          <v-avatar size="36" class="">
            <img
              alt="LoveStyle Redirect"
              src="@/assets/LSR_logo.png"
            >
          </v-avatar>
          <span class="ml-3">
            LoveStyle Redirect
          </span>
        </router-link>
      </v-col>
    </v-footer>
    <snackbar ref="toast" />
  </v-app>
</template>

<script>
import AppMenu from '@/components/AppMenu.vue'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'App',
  // FIXME: komment, komponensek magyarázata, mi mit csinál
  components: {
    AppMenu,
    Snackbar,
  },
  data() {
    return {
      list: '',
      input: {
        show: '',
      },
    }
  },
  // FIXME: komment, a toastert hogyhogy csak itt kell regisztrálni?
  mounted() {
    this.$root.$toast = this.$refs.toast.show
  },
  methods: {
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped>
  .m {
    &-link {
      color: currentColor;
      text-decoration: none;
      cursor: pointer;
    }
  }
</style>
