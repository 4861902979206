<template>
  <v-main>
    <v-container
      fluid
      fill-height
    >
      <v-row class="mx-0 px-0 justify-center">
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="3"
        >
          <v-card class="elevation-12">
            <v-toolbar>
              <v-toolbar-title>404</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'PageNotFound',
  data() {
    return {
    }
  },
}
</script>
